// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '../../node_modules/@fullcalendar/bootstrap/main.css';
@import '../../node_modules/@fullcalendar/resource-timeline/main.css';
@import '../../node_modules/@fullcalendar/timeline/main.css';
@import '../../node_modules/@fullcalendar/bootstrap/main.css';

.calendar-app {
  //font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  //font-size: 14px;
  //margin: 0 0 3em;

}

.fc.fc-ltr.fc-bootstrap {
  padding: 5px;
}

.fc-header-toolbar {
  padding: 0.3rem;
  padding-bottom: 0;
}

// mehr platz für die 3. ebene
.fc-cell-content > span:nth-child(3).fc-expander-space > span.fc-icon {
  //border:1px solid red;
  width: 0;
}

.fc-timeline tr.fc-scrollgrid-section-body table.fc-datagrid-body td:not(:first-child) {
  text-align: right;
}

.fc-timeline td.fc-resource:first-child span.fc-datagrid-cell-main {
  display: inline-block;
}

.fc .fc-button {
  border-radius: 0;
  background: #EFEADB;
  color: darkslategray;
  border-color: darken(#EFEADB, 15);
}

.fc-button-primary {
  &:disabled {
    background-color: #A9BAAD !important;
    border-color: darken(#A9BAAD, 15) !important;
    color: lightgray !important;
  }

  //background: none;
  //border-color: gray;
}

.fc .fc-button:hover, .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: darken(#0081B8, 25);
}

.calendar-app-top {
  //margin: 0 0 3em;
}

.calendar-app-calendar {
  //margin: 0 auto;
  //max-width: 900px;
}

.calendar-app-sidebar {
  background: yellow;
}

.react-datepicker-wrapper {
  display: block;
}

.fc-header-toolbar > .fc-toolbar-chunk:last-child {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.fc-duration-slider {
  margin-left: 14px;
  margin-right: 14px;
}

.list-page {
  //min-height:80vh;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
  background-color: lighten(#A9BAAD, 20) !important;
  color: lighten(darkslategray, 3) !important;
}

.fc .fc-timeline-slot-cushion {
  text-decoration: none;
  color: inherit;
}

@mixin gradientAnimation( $start, $end, $transTime ){
  //background-image: repeating-linear-gradient(-45deg, transparent, lighten(rgb(215, 203, 166), 8) 16px);
  //background-size: 200%;
  ////background-image: repeating-linear-gradient(-45deg, $start, $end, 16px);
  //transition: background-position $transTime;
  background: linear-gradient(rgba(0,0,0,.26), rgba(255,255,255,.26) 50%), linear-gradient(315deg, #78a804 25%, #669a04 25%, #669a04 50%, #78a804 50%, #78a804 75%, #669a04 75%, #669a04);
  background-size:16px 16px;
  height:8px;
  width:500px;
  overflow:hidden;
  border-radius:4px;
  border-bottom:1px solid rgba(255,255,255,.75);
}
.fc .fc-event {

  padding-left: 2px;
  white-space: nowrap !important;
  
  .fc-event-main {
    div {
      overflow-x: hidden;
      text-overflow: clip;

    }
  }
  
  &.group, &.project, &.event {
    //&.calculating {
      //-webkit-transition: background 1s ease-out;
      //-moz-transition: background 1s ease-out;
      //-o-transition: background 1s ease-out;
      //transition: background 1s ease-out;
    
      //@include gradientAnimation( 'blue', 'orange', 1.5s );
    //}
    text-shadow: rgba(0, 0, 0, 0.4) 0.1em 0.1em 0.15em;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    //background-size:1px 200px;
  }

  &.employee {
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
    text-shadow: rgba(0, 0, 0, 0.3) 0.08em 0.1em 0.15em;
    &.invalid {
      background-image: repeating-linear-gradient(-45deg, transparent, lighten(rgb(215, 203, 166), 3) 16px);
      border: 2px dashed #FF681F !important;
    }
    &.attention {
      background-image: repeating-linear-gradient(-45deg, transparent, lighten(#FF681F, 8) 16px);
    }
  }
}
